/* global rp$, window */
'use strict';

import './login_combined.scss';

var THE_SCRIPT = function() {
	const $onReady = require('public/js/lib/on-ready');

	const moduleClass = '.login-combined-module';
	const loginFormClass = '.login-user-form';
	const resetFormClass = '.reset-password-form';
	const createUserFormClass = '.create-user-form';
	const newMemberContactUsFormClass = '.new-member-contact-us-form';	
	const loginLinkClass = '.login-link';
	const createUserlinkClass = '.create-user';
	const forgotPasswordLinkClass = '.forgot-password';
	const newMemberContactUsFormLinkClass = '.new-member-contact-us';
	const loginCombinedCaptchaDivId = '#login-combined-captcha-div';

	let $moduleWrapper,
		$loginFormWrapper,
		$forgotPasswordFormWrapper,
		$createUserFormWrapper,
		$newMemberContactUsFormWrapper,
		$captchaWrapper;

	const PAGES = {
		login: 'login',
		signup: 'signup',
		reset: 'forgot-password',
		newMemberContactUs: 'new-member-contact-us',
	};

	function toggleDivs(newElem, prevElem, showCaptcha) {
		const width = newElem.parent().width();
		// Animate previous div out
		// Supports individual items or arrays
		prevElem = Array.isArray(prevElem) ? prevElem : [prevElem];

		prevElem.forEach(function (elem) {
			elem.animate({
				opacity: 0,
				left: `-${width}px`,
			}, 'fast').delay(500).css({ display: 'none' });
		});

		// Animate new div in
		newElem.css({ display: 'block', 'left': `${width}px` }).animate({
			opacity: 1,
			left: 0,
		}, 'fast');

		if (showCaptcha) {
			$captchaWrapper.css({ display: 'block' });
		} else {
			$captchaWrapper.css({ display: 'none' });
		}

		$moduleWrapper.height(newElem.height());
	}

	function handleForgotPasswordClick(e) {
		e.preventDefault();
		//Show Password Form
		handleViewUpdate(PAGES.reset, true);
	}

	function handleNewMemberContactUsFormClick(e) {
		e.preventDefault();
		//Show New Member Contact Us Form
		handleViewUpdate(PAGES.newMemberContactUs, true);
	}	

	function handleCreateUser(e) {
		e.preventDefault();
		//Show Create User Form
		handleViewUpdate(PAGES.signup, true);
	}

	function handleLoginClick(e) {
		e.preventDefault();
		//Show Login Form
		handleViewUpdate(PAGES.login, true);
	}

	function attachInputEvents() {
		rp$('input').each(function() {
			if(rp$(this).val()) {
				rp$(this).closest('.field').addClass('active');
			}
		});

		rp$('input').on('blur', function(){
			if(!rp$(this).val()){
				rp$(this).closest('.field').removeClass('active');
			}
		}).on('focus', function(){
			rp$(this).closest('.field').addClass('active');
		}).on('input change', function(){
			if(rp$(this).val()){
				rp$(this).closest('.field').addClass('active');
			} else {
				rp$(this).closest('.field').removeClass('active');
			}
		});
	}

	function updateWindowOnPopstate() {
		window.onpopstate = function() {
			const path = rp_app.location.pathname.split('/');
			handleViewUpdate(path[path.length - 1]);
		};
	}

	function buildHistoryUrl(route) {
		const baseUrl = rp_app.location.getBase();
		return `${baseUrl}/account/${route}`;
	}

	function updateUrl(route) {
		const historyUrl = buildHistoryUrl(route);
		window.history.pushState(null, '', historyUrl);
	}

	function handleViewUpdate(view, doUrlUpdate = false) {
		if (view === PAGES.signup) {
			toggleDivs($createUserFormWrapper, $loginFormWrapper, false);
		} else if (view === PAGES.reset) {
			toggleDivs($forgotPasswordFormWrapper, $loginFormWrapper, true);
		} else if (view === PAGES.newMemberContactUs) {
			toggleDivs($newMemberContactUsFormWrapper, $loginFormWrapper, true);
		} else {
			toggleDivs($loginFormWrapper, [$createUserFormWrapper, $forgotPasswordFormWrapper, $newMemberContactUsFormWrapper], true);
		}

		if (doUrlUpdate) {
			updateUrl(view);
		}
	}

	(function LoginCombinedModule() {
		$onReady({
			rp$,
			window,
			label: 'login_combined',
			fn: function() {
				$moduleWrapper = rp$(moduleClass);
				$loginFormWrapper = rp$(loginFormClass, $moduleWrapper);
				$forgotPasswordFormWrapper = rp$(resetFormClass, $moduleWrapper);
				$newMemberContactUsFormWrapper = rp$(newMemberContactUsFormClass, $moduleWrapper);
				$createUserFormWrapper = rp$(createUserFormClass, $moduleWrapper);
				$captchaWrapper = rp$(loginCombinedCaptchaDivId, $moduleWrapper);

				if(!rp_app.env.is_webstore_plugin) {
					$loginFormWrapper.find(forgotPasswordLinkClass).on('click', handleForgotPasswordClick);
					$loginFormWrapper.find(createUserlinkClass).on('click', handleCreateUser);
					$loginFormWrapper.find(newMemberContactUsFormLinkClass).on('click', handleNewMemberContactUsFormClick);
					$forgotPasswordFormWrapper.find(loginLinkClass).on('click', handleLoginClick);
					$createUserFormWrapper.find(loginLinkClass).on('click', handleLoginClick);
					$newMemberContactUsFormWrapper.find(loginLinkClass).on('click', handleLoginClick);

					updateWindowOnPopstate();
				}

				attachInputEvents();
			}
		});
	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
